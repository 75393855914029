<template>
  <div class="app-container">
    <!--    搜索区域-->
    <el-card class="filter-container" shadow="never">
      <div>
        <el-form :inline="true" :model="listQuery" size="small" label-width="140px">
          <el-form-item label="昵称：">
            <el-input style="width: 230px" v-model="listQuery.keyword" placeholder="请输入昵称" clearable></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div style="margin-left: 55px;">
        <el-button type="primary" size="small" @click="handleSearchList()">查询</el-button>
        <el-button size="small" @click="handleResetSearch()">重置</el-button>
      </div>
    </el-card>
    <!--    功能区域-->
    <div style="margin: 20px 0">
<!--      <el-button type="primary" @click="handleAdd" >添加</el-button>-->
      <el-button type="danger" @click="deleteBatch" >批量删除</el-button>
    </div>
    <!--    表格区域-->
    <div class="table-container">
      <el-table ref="userTable"
                :data="tableData"
                style="width: 100%;"
                @selection-change="handleSelectionChange"
                v-loading="listLoading" border>
        <el-table-column type="selection" width="60" align="center"></el-table-column>
        <el-table-column label="编号" width="120" align="center">
          <template #default="scope">{{scope.row.id}}</template>
        </el-table-column>
        <el-table-column label="昵称" align="center">
          <template #default="scope">{{scope.row.nick_name}}</template>
        </el-table-column>
        <el-table-column label="头像" width="200" align="center">
          <template #default="scope"><img style="height: 80px" :src="scope.row.head_img"></template>
        </el-table-column>
        <el-table-column label="设备码"  align="center">
          <template #default="scope">{{scope.row.device_code}}</template>
        </el-table-column>
        <el-table-column label="注册来源"  align="center" width="150">
          <template #default="scope">{{scope.row.source}}</template>
        </el-table-column>
        <el-table-column label="会员类型"  align="center" width="150">
          <template #default="scope">{{scope.row.member_type}}</template>
        </el-table-column>
        <el-table-column label="推广人"  align="center" width="120">
          <template #default="scope">{{scope.row.superior_nick_name}}</template>
        </el-table-column>
        <el-table-column label="余额"  align="center" width="120">
          <template #default="scope">{{scope.row.balance}}</template>
        </el-table-column>
        <el-table-column label="操作" width="250" align="center">
          <template #default="scope">
            <el-button size="mini" type="primary" plain @click="handleUpdate(scope.row)">编辑设备码</el-button>
            <el-button size="mini" type="danger" @click="handleDelete(scope.row)">删除用户</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!--    分页-->
    <div style="margin: 10px 0">
      <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          layout="total, sizes,prev, pager, next,jumper"
          :page-size="listQuery.pageSize"
          :page-sizes="[5,10,15,20,50,100,200,500,1000]"
          :current-page.sync="listQuery.pageNum"
          :total="total">
      </el-pagination>
    </div>

    <el-dialog
        :title="isEditFlag == true?'编辑':'添加'"
        v-model="dialogVisible"
        width="40%">
      <el-form :model="userModel"
               :rules="rules"
               ref="userFrom"
               label-width="150px"
               size="small">
<!--        <el-form-item label="昵称：" prop="nick_name">
          <el-input v-model="userModel.nick_name" style="width: 80%" clearable></el-input>
        </el-form-item>
        <el-form-item label="手机号：" prop="mobile">
          <el-input v-model="userModel.mobile" style="width: 80%" clearable></el-input>
        </el-form-item>
        <el-form-item label="头像：" prop="head_img">
          <el-input v-model="userModel.head_img" style="width: 80%" clearable></el-input>
        </el-form-item>
        <el-form-item label="openid：" prop="openid">
          <el-input v-model="userModel.openid" style="width: 80%" clearable></el-input>
        </el-form-item>-->
        <el-form-item label="设备码：" prop="device_code">
          <el-input v-model="userModel.device_code" style="width: 80%" clearable></el-input>
        </el-form-item>
<!--        <el-form-item label="来源：" prop="source">
          <el-input v-model="userModel.source" style="width: 80%" clearable></el-input>
        </el-form-item>-->
<!--        <el-form-item label="会员类型：" prop="member_type">
          <el-input v-model="userModel.member_type" style="width: 80%" clearable></el-input>
        </el-form-item>-->
      </el-form>
      <template #footer>
          <span class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="handleDialogConfirm('userFrom')">提交</el-button>
          </span>
      </template>
    </el-dialog>

  </div>
</template>

<script>

import {batchDeleteUser, deleteUser, fetchList, createUser, updateUser} from "@/api/user";

const defaultListQuery = {
  pageNum: 1,
  pageSize: 5,
  keyword: null,
};

const defaultUser = {
  openid: null,
  nick_name: null,
  mobile: null,
  head_img: null,
  device_code: null,
  source: null,
  member_type: null,
  balance: 0,
};

export default {
  name: 'User',
  components: {},
  data() {
    return {
      listQuery: Object.assign({}, defaultListQuery),
      tableData: [],
      total: null,
      listLoading: false,
      ids: [],
      rules: {
        nick_name: [{required: true, message: '请输入', trigger: 'blur'},],
        openid: [{required: true, message: '请输入', trigger: 'blur'},],
        mobile: [{required: true, message: '请输入', trigger: 'blur'},],
        head_img: [{required: true, message: '请输入', trigger: 'blur'},],
        device_code: [{required: true, message: '请输入', trigger: 'blur'},],
        source: [{required: true, message: '请输入', trigger: 'blur'},],
      },
      dialogVisible: false,
      isEditFlag: false,
      userModel: Object.assign({}, defaultUser),
    }
  },
  created() {
    this.getTableList()
  },
  methods: {
    //重置
    handleResetSearch() {
      this.listQuery = Object.assign({}, defaultListQuery);
      this.getTableList();
    },
    //查询
    handleSearchList() {
      this.listQuery.pageNum = 1;
      this.getTableList();
    },
    //多选
    handleSelectionChange(val){
      this.ids = val.map(v => v.id);
    },
    // 改变当前每页的个数触发
    handleSizeChange(val) {
      this.listQuery.pageNum = 1;
      this.listQuery.pageSize = val;
      this.getTableList();
    },
    // 改变当前页码触发
    handleCurrentChange(val) {
      this.listQuery.pageNum = val;
      this.getTableList();
    },

    //删除
    handleDelete(row){
      this.$confirm('确定删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let data =  {
          "dataId": row.id,
        };
        deleteUser(data).then(res => {
          if (res.code === '0') {
            this.$message({
              type: "success",
              message: "删除成功"
            })
          } else {
            this.$message({
              type: "error",
              message: res.msg
            })
          }
          this.getTableList();
        });
      });
    },
    //批量删除
    deleteBatch() {
      if (!this.ids.length) {
        this.$message.warning("请选择数据！")
        return
      }
      this.$confirm('确定删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let data =  {
          "dataId": this.ids,
        };
        batchDeleteUser(data).then(res => {
          if (res.code === '0') {
            this.$message({
              type: "success",
              message: "批量删除成功"
            })
          } else {
            this.$message({
              type: "error",
              message: res.msg
            })
          }
          this.getTableList();
        });
      });
    },
    //列表
    getTableList() {
      this.listLoading = true;
      fetchList(this.listQuery).then(res => {
            if (res.code === '0') {
              this.listLoading = false;
              this.tableData = res.data.records;
              this.total = res.data.total;
            }
      })
    },
    //添加路由
    handleAdd() {
      this.dialogVisible = true;
      this.isEditFlag = false;
      this.userModel = Object.assign({},defaultUser);
    },
    //修改路由
    handleUpdate(row) {
      this.dialogVisible = true;
      this.isEditFlag = true;
      this.userModel = Object.assign({},row);
    },
    //确认操作
    handleDialogConfirm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$confirm('是否提交数据', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            if (this.isEditFlag) {
              updateUser(this.userModel).then(res => {
                if (res.code === '0') {
                  this.$message({
                    message: '修改成功',
                    type: 'success',
                  });
                }else {
                  this.$message({
                    type: "error",
                    message: res.msg
                  })
                }
                this.dialogVisible =false;
                this.getTableList();
              });

            }else {
              createUser(this.userModel).then(res => {
                if (res.code === '0') {
                  this.$message({
                    message: '提交成功',
                    type: 'success',
                  });
                }else {
                  this.$message({
                    type: "error",
                    message: res.msg
                  })
                }
                this.dialogVisible =false;
                this.getTableList();
              });
            }
          });
        }
      });
    },

  }
}
</script>
<style scoped>


</style>
