import request from '@/utils/request';

//列表
export function fetchList(data) {
  return request({
    url : '/user/info/list.php',
    method : 'post',
    data : data
  })
}
//清除设备码
export function deleteDevice(data) {
  return request({
    url : '/user/info/deleteDevice.php',
    method : 'post',
    data : data
  })
}

//删除
export function deleteUser(data) {
  return request({
    url : '/user/info/delete.php',
    method : 'post',
    data : data
  })
}
//批量删除
export function batchDeleteUser(data) {
  return request({
    url : '/user/info/deleteBatch.php',
    method : 'post',
    data : data
  })
}

//新增
export function createUser(data) {
  return request({
    url : '/user/info/portalSaveUser.php',
    method : 'post',
    data : data
  })
}

//修改
export function updateUser(data) {
  return request({
    url :'/user/info/updateUserDevice.php',
    method : 'post',
    data : data
  })
}
